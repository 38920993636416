import { useReducer } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useToastContext } from "../../context/toastContext";
import {
	DAILY_WEEKLY_ROTATION_CONSTANTS,
	FILE_ACCEPT_TYPES,
	FILE_CONTENT_TYPES,
	GENERAL_CONFIGURATION_STEPS,
	QUERY_CONSTANTS,
	TOAST_REDUCER_CONSTANTS,
	UPLOAD_CATEGORY,
	WEEKLY_DAILY_REDUCER_CONSTANTS,
} from "../../constants";
import MESSAGE_STRINGS from "../../constants/en-us";
import {
	getUploadPresignedUrl,
	getWeeklyRotationsExportData,
	uploadToPresignedUrl,
	processWeeklyRotations,
	getWeeklyRotationsData,
	getDailyRotationsData,
	fetchDailyRotationsExportData,
	updateWeeklyRotations,
	updateDailyRotations,
} from "../../utils/apiHelper";
import {
	getCrewResources,
	getDailyRotationsViewData,
	getRotationResources,
	getWeeklyRotationViewData,
	simulateUrlClick,
} from "../../utils/helpers";
import {
	updateStepStatusCompleted,
	updateStepStatusInProgress,
	useGeneralConfigContext,
} from "../../context/generalConfigContext";
import { updateRxjsState } from "../../utils/rxjs";

const initialValues = {
	viewData: [],
	areRotationsPresentForNextWeek: true,
	areRotationsPresentForNextDay: true,
	weeklyRotationsErrorMessage: null,
	currentView: DAILY_WEEKLY_ROTATION_CONSTANTS.VIEWS.WEEK,
	selectedDate: {
		month: new Date().getMonth(),
		year: new Date().getFullYear(),
	},
	crews: [],
	rotations: [],
	showInitialStep: true,
	isPreviewComponentEnabled: false,
	fileObject: null,
	errorMessages: null,
	isFileUploadedOnce: false,
};

function reducer(state, action) {
	const { type, payload } = action;
	switch (type) {
		case WEEKLY_DAILY_REDUCER_CONSTANTS.SET_WEEKLY_ROTATIONS: {
			const {
				newUnappliedRotationExists = false,
				areRotationsPresentForNextDay = true,
				areRotationsPresentForNextWeek = true,
				data = [],
				rotations,
			} = payload.response;
			const viewData = [...getWeeklyRotationViewData(data)];
			const newRotations = rotations
				? getRotationResources(rotations)
				: state.rotations;
			return {
				...state,
				doesNewRotationExist: newUnappliedRotationExists,
				areRotationsPresentForNextWeek,
				areRotationsPresentForNextDay,
				viewData,
				rotations: newRotations,
				isPreviewComponentEnabled: true,
				showInitialStep: false,
				isFileUploadedOnce: true,
				requireNewRotationAssignment: payload.requireNewRotationAssignment,
			};
		}
		case WEEKLY_DAILY_REDUCER_CONSTANTS.SET_DAILY_ROTATIONS: {
			const { data = [], crews = [] } = payload.response;
			const viewData = [...getDailyRotationsViewData(data)];
			const newCrews = crews ? getCrewResources(crews) : state.crews;
			return {
				...state,
				viewData,
				crews: newCrews,
				isPreviewComponentEnabled: true,
				showInitialStep: false,
			};
		}
		case WEEKLY_DAILY_REDUCER_CONSTANTS.ON_UPLOAD: {
			return {
				...state,
				isPreviewComponentEnabled: false,
				errorMessages: "",
				requireNewRotationAssignment: false,
			};
		}
		case WEEKLY_DAILY_REDUCER_CONSTANTS.SET_FILE_OBJECT: {
			return {
				...state,
				fileObject: payload.file,
			};
		}
		case WEEKLY_DAILY_REDUCER_CONSTANTS.SET_INITIAL_STEP: {
			return {
				...state,
				showInitialStep: payload.showInitialStep,
			};
		}
		case WEEKLY_DAILY_REDUCER_CONSTANTS.SET_ERROR_MESSAGES: {
			return {
				...state,
				errorMessages: payload.errorMessages,
			};
		}
		case WEEKLY_DAILY_REDUCER_CONSTANTS.SET_PREVIW: {
			return {
				...state,
				isPreviewComponentEnabled: payload.isPreviewComponentEnabled,
				requireNewRotationAssignment: false,
			};
		}
		case WEEKLY_DAILY_REDUCER_CONSTANTS.SET_VIEW_DATA: {
			return {
				...state,
				viewData: payload.viewData,
			};
		}
		case WEEKLY_DAILY_REDUCER_CONSTANTS.SET_SELECTED_DATE: {
			return {
				...state,
				selectedDate: payload.selectedDate,
			};
		}
		case WEEKLY_DAILY_REDUCER_CONSTANTS.ON_VIEW_CHANGE: {
			return {
				...state,
				currentView: payload.view,
				viewData: [],
			};
		}
		default:
			return state;
	}
}

const updateInitialStep = (step = false) => {
	return {
		type: WEEKLY_DAILY_REDUCER_CONSTANTS.SET_INITIAL_STEP,
		payload: { showInitialStep: step },
	};
};
const setViewData = (data = []) => {
	return {
		type: WEEKLY_DAILY_REDUCER_CONSTANTS.SET_VIEW_DATA,
		payload: { viewData: data },
	};
};
const setErrorMessages = (msg) => {
	return {
		type: WEEKLY_DAILY_REDUCER_CONSTANTS.SET_ERROR_MESSAGES,
		payload: { errorMessages: msg },
	};
};
const setSelectedDate = (date) => {
	const selectedDate = { month: date.getMonth(), year: date.getFullYear() };
	return {
		type: WEEKLY_DAILY_REDUCER_CONSTANTS.SET_SELECTED_DATE,
		payload: { selectedDate },
	};
};

export function useWeeklyDailyRotations(
	plantId,
	refetchGeneralStatus,
	onNextClickAPI
) {
	const [state, dispatch] = useReducer(reducer, initialValues);
	const { toastDispatch } = useToastContext();
	const queryClient = useQueryClient();

	const { selectedDate, currentView, crews, viewData } = state;
	const {
		generalConfigDispatch,
		generalConfigState: { isMaintenanceMode },
	} = useGeneralConfigContext();
	const isWeeklyRotation =
		currentView === DAILY_WEEKLY_ROTATION_CONSTANTS.VIEWS.WEEK;
	const {
		refetch: exportWeeklyRotationsAPI,
		isLoading: isDownloadUrlLoading,
		isFetching: isDownloadUrlFetching,
	} = useQuery(
		[QUERY_CONSTANTS.EXPORT_WEEKLY_ROTATIONS],
		() => getWeeklyRotationsExportData({ plantId }),
		{
			onSuccess: (response) => {
				if (response && response.url) {
					simulateUrlClick(response.url, FILE_ACCEPT_TYPES.XLSX);
				}
			},
			onError: (error) => {
				if (error && error.response && error.response.data) {
					console.info("Export Weekly Daily Data Error", error);
				}
			},
			refetchOnWindowFocus: false,
			retry: false,
			enabled: false,
		}
	);
	const showError = (isViewEmpty = true) => {
		if (isViewEmpty) dispatch(setViewData([]));
		toastDispatch({
			type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
			payload: {
				message: MESSAGE_STRINGS["Toast.message.ERROR"],
			},
		});
	};
	const {
		isLoading: isFetchWeeklyRotationsLoading,
		isFetching: isFetchWeeklyRotationsFetching,
		refetch: getWeeklyRotationsAPI,
	} = useQuery(
		[QUERY_CONSTANTS.FETCH_WEEKLY_ROTATIONS, { selectedDate }],
		() =>
			getWeeklyRotationsData({
				factoryID: plantId,
				month: selectedDate.month,
				year: selectedDate.year,
			}),
		{
			onSuccess: (response) => {
				if (response && response.data) {
					dispatch({
						type: WEEKLY_DAILY_REDUCER_CONSTANTS.SET_WEEKLY_ROTATIONS,
						payload: {
							response,
							requireNewRotationAssignment:
								isMaintenanceMode && response.requireNewRotationAssignment,
						},
					});
					const { areRotationsPresentForNextDay } = response;

					if (!areRotationsPresentForNextDay)
						updateStepStatusInProgress(
							generalConfigDispatch,
							GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION
						);
				}
			},
			onError: () => {
				showError();
			},
			retry: false,
			refetchOnWindowFocus: false,
			enabled: isWeeklyRotation,
		}
	);
	const {
		isLoading: isFetchDailyRotationsLoading,
		isFetching: isFetchDailyRotationsFetching,
		refetch: getDailyRotationsAPI,
	} = useQuery(
		[QUERY_CONSTANTS.FETCH_DAILY_ROTATIONS, { selectedDate }],
		() =>
			getDailyRotationsData({
				factoryID: plantId,
				month: selectedDate.month,
				year: selectedDate.year,
			}),
		{
			onSuccess: (response) => {
				if (response && response.data) {
					dispatch({
						type: WEEKLY_DAILY_REDUCER_CONSTANTS.SET_DAILY_ROTATIONS,
						payload: { response },
					});
				}
			},
			onError: () => {
				showError();
			},
			retry: false,
			refetchOnWindowFocus: false,
			enabled: !isWeeklyRotation,
		}
	);

	const {
		isLoading: isProcessWeeklyRotationsLoading,
		isError: isProcessWeeklyRotationsError,
		mutate: processWeeklyRotationsAPI,
	} = useMutation((data) => processWeeklyRotations(data), {
		onSuccess: () => {
			if (isWeeklyRotation) {
				getWeeklyRotationsAPI();
			} else {
				getDailyRotationsAPI();
			}
			if (isMaintenanceMode) {
				refetchGeneralStatus();
			}
			updateStepStatusCompleted(
				generalConfigDispatch,
				GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION
			);
			dispatch({
				type: WEEKLY_DAILY_REDUCER_CONSTANTS.SET_PREVIW,
				payload: { isPreviewComponentEnabled: true },
			});
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
				payload: {
					message: MESSAGE_STRINGS["GPFC.toast.success"],
				},
			});
			if (!isMaintenanceMode){ 
				onNextClickAPI();
			}
			updateRxjsState({ isFGCComplete: true });
		},
		onError: (error) => {
			if (error?.response?.data?.message) {
				dispatch(setErrorMessages(error.response.data.message));
			}
		},
	});

	const {
		isLoading: isUploadWeeklyRotationsLoading,
		isError: isUploadWeeklyRotationsError,
		mutate: uploadWeeklyRotationsAPI,
	} = useMutation(
		(file) => {
			dispatch({
				type: WEEKLY_DAILY_REDUCER_CONSTANTS.ON_UPLOAD,
			});
			return getUploadPresignedUrl({
				plantId,
				fileName: file.name,
				category: UPLOAD_CATEGORY.WEEKLY_ROTATIONS,
				contentType: FILE_CONTENT_TYPES.XLSX,
			});
		},
		{
			onSuccess: async (response, file) => {
				updateStepStatusInProgress(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION
				);
				await uploadToPresignedUrl(response.data, file);
				dispatch(updateInitialStep(false));
				processWeeklyRotationsAPI({
					fileName: file.name,
					factoryID: plantId,
				});
			},
			onError: () => {
				dispatch(updateInitialStep(false));
			},
		}
	);

	const {
		refetch: exportDailyRotationsAPI,
		isLoading: isExportDailyLoading,
		isFetching: isExportDailyFetching,
	} = useQuery(
		[QUERY_CONSTANTS.EXPORT_DAILY_ROTATIONS],
		() =>
			fetchDailyRotationsExportData({
				factoryID: plantId,
				month: selectedDate.month,
				year: selectedDate.year,
			}),
		{
			onSuccess: (response) => {
				if (response && response.url) {
					simulateUrlClick(response.url, FILE_ACCEPT_TYPES.CSV);
				}
			},
			onError: (error) => {
				if (error && error.response && error.response.data) {
					console.info("Export Daily Data Error", error);
				}
			},
			refetchOnWindowFocus: false,
			retry: false,
			enabled: false,
		}
	);

	const {
		mutate: putWeeklyRotations,
		reset: updateWeeklyRotationReset,
		isLoading: isUpdateWeeklyRotationsLoading,
	} = useMutation((data) => updateWeeklyRotations(data), {
		onSuccess: (res) => {
			queryClient.invalidateQueries(QUERY_CONSTANTS.FETCH_WEEKLY_ROTATIONS);
			if (res && res.message) {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
					payload: {
						message: res.message,
					},
				});
			}
			updateWeeklyRotationReset();
		},
		onError: () => {
			showError(false);
			updateWeeklyRotationReset();
		},
	});

	const {
		mutate: putDailyRotations,
		reset: updateDailyRotationsReset,
		isLoading: isUpdateDailyRotationsLoading,
	} = useMutation((data) => updateDailyRotations(data), {
		onSuccess: (res) => {
			queryClient.invalidateQueries(QUERY_CONSTANTS.FETCH_DAILY_ROTATIONS);
			if (res && res.message) {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
					payload: {
						message: res.message,
					},
				});
			}
			updateDailyRotationsReset();
		},
		onError: () => {
			showError(false);
			updateDailyRotationsReset();
		},
	});

	function onDateChanges(date) {
		dispatch(setSelectedDate(date));
	}
	function handleViewChange(view) {
		dispatch({
			type: WEEKLY_DAILY_REDUCER_CONSTANTS.ON_VIEW_CHANGE,
			payload: { view },
		});
	}
	function onExport() {
		if (!isWeeklyRotation) {
			exportDailyRotationsAPI();
			return;
		}
		exportWeeklyRotationsAPI();
	}

	/**
	 * Function to call Save API for Event Data
	 * @param {Object} event Data to be Updated for Weekly/Daily
	 */
	function onEventSave(event) {
		if (isWeeklyRotation) {
			const { selectedItem, rotationSlotStart } = event;
			putWeeklyRotations({
				factoryID: plantId,
				rotationName: selectedItem,
				rotationSlotStart,
			});
		} else {
			const { shiftId, dateTimestamp, selectedItem } = event;
			putDailyRotations({
				factoryID: plantId,
				shiftId,
				dateTimestamp,
				crewId: selectedItem,
			});
		}
	}

	/**
	 * Fucntion to check Duplicate Crew Assignment
	 * @param {Number} selectedCrewId Selected Crew Id from User
	 * @param {Date} eventDateTimeStamp Date time stamp of Day
	 * @param {Number} eventShiftId ShiftID to check duplicate Crew
	 * @returns true/false
	 */
	const isDuplicateCrewAssigned = (
		selectedCrewId,
		eventDateTimeStamp,
		eventShiftId
	) => {
		// Handles 'None' case - which does not require validation
		if (
			crews.some((item) => item.id === selectedCrewId && item.text === "None")
		) {
			return false;
		}
		// Handles duplicate crew finding
		const dateStampData = viewData.filter(
			(item) =>
				item.dateTimestamp === eventDateTimeStamp &&
				item.shiftId !== eventShiftId
		);
		return dateStampData.some((obj) => obj.crew === selectedCrewId);
	};

	const showBackdropLoader =
		isDownloadUrlLoading ||
		isDownloadUrlFetching ||
		isFetchWeeklyRotationsLoading ||
		isFetchWeeklyRotationsFetching ||
		isFetchDailyRotationsLoading ||
		isFetchDailyRotationsFetching ||
		isExportDailyLoading ||
		isExportDailyFetching ||
		isUpdateWeeklyRotationsLoading ||
		isUpdateDailyRotationsLoading;
	const isUploadLoading =
		isUploadWeeklyRotationsLoading || isProcessWeeklyRotationsLoading;
	const isUploadError =
		isUploadWeeklyRotationsError || isProcessWeeklyRotationsError;

	return {
		state,
		dispatch,
		onExport,
		showBackdropLoader,
		uploadWeeklyRotationsAPI,
		isUploadError,
		isUploadLoading,
		isWeeklyRotation,
		onDateChanges,
		handleViewChange,
		onEventSave,
		isDuplicateCrewAssigned,
	};
}

export default {};
